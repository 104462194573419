<template>
    <mercur-card class="full-height-layout fill form mx-4 mb-3">
        <form @submit.prevent="submitHoliday" class="full-height-layout fill">
            <h2 class="font-weight-normal px-4">
                <span v-if="isEdit">Edit holiday <span v-if="holidayName" v-once>{{holidayName}}</span></span>
                <span v-else>Add holiday</span>
            </h2>

            <div class="fill px-4">
                <div class="row">
                    <div class="col-12">
                        <mercur-input
                            v-model="$v.holidayName.$model"
                            :disabled="loading"
                            :class="{'form-invalid': $v.holidayName.$error}">
                            Holiday name
                            <template slot="note">
                                <span class="form-error" v-if="!$v.holidayName.required">Name is required</span>
                            </template>
                        </mercur-input>
                    </div>
                    <div class="col-6">
                        <div class="daterangepicker-wrapper">
                            <date-range-picker
                                @update="holidayRangeUpdate"
                                :timePicker="!allDay"
                                :timePicker24Hour="true"
                                :dateRange="holidayDateRange"
                                :ranges="false"
                                opens="right"
                                ref="dateRangePicker"
                            >
                                <template slot="preview-range" slot-scope="preview">
                                    <strong>{{preview.startText}}</strong>
                                    &nbsp;to&nbsp;
                                    <strong>{{preview.endText}}</strong>
                                </template>
                                <template slot="input">
                                    <mercur-input
                                        v-model="parsedHolidayRange"
                                        :disabled="loading"
                                        @keypress.enter.prevent="handleManualDateInput">
                                        Date range
                                    </mercur-input>
                                </template>
                            </date-range-picker>
                        </div>
                    </div>

                    <div class="col-6 flex align-items-center">
                        <mercur-checkbox v-model="allDay" :disabled="loading">All day</mercur-checkbox>
                        <mercur-checkbox class="ml-4" v-model="yearly" :disabled="loading">Yearly on the same date</mercur-checkbox>
                    </div>
                </div>

            </div>

            <div>
                <mercur-button type="submit" :disabled="$v.$invalid || loading" class="btn btn-raised btn-yellow text-uppercase ml-4">Submit</mercur-button>
            </div>
        </form>
    </mercur-card>
</template>
<script>
import CONFIG from '@root/config'
import { validationMixin } from 'vuelidate'
import {
    required,
} from 'vuelidate/lib/validators'
import moment from 'moment'
import DateRangePicker from '@/components/utils/DateRangePicker'

export default {
    name: 'SupplierHolidayAdd',
    components: { DateRangePicker },
    mixins: [ validationMixin ],
    validations: {
        holidayName: {
            required,
        },
        holidayFrom: {
            required,
        },
        holidayTo: {
            required,
        },
        yearly: {},
    },
    data () {
        return {
            holidayName: '',
            holidayFrom: null,
            holidayTo: null,
            yearly: false,
            allDay: this.isEdit,
            loading: false,
        }
    },
    computed: {
        holidayDateRange () {
            return {
                startDate: (this.holidayFrom || moment()).toDate(),
                endDate: (this.holidayTo || moment()).toDate(),
            }
        },
        isEdit () {
            return !!this.$route.params.holidayId
        },
        formAction () {
            let url = CONFIG.API.ROUTES.SUPPLIERS.HOLIDAYS.ADD

            if (this.isEdit) {
                url = CONFIG.API.ROUTES.SUPPLIERS.HOLIDAYS.UPDATE.replace('{holidayId}', this.$route.params.holidayId)
            }

            return url.replace('{supplierId}', this.supplierId)
        },
        parsedHolidayRange: {
            get () {
                if (this.holidayFrom === null || this.holidayTo === null) {
                    return ''
                }
                return this.formatDate(this.holidayFrom, this.allDay) + ' - ' + this.formatDate(this.holidayTo, this.allDay)
            },
            set (value) {
                if (typeof value !== 'string') {
                    return
                }

                let [start, end] = value.split(' - ')

                start = moment(start)
                end = moment(end)

                if (start.isValid()) {
                    this.holidayFrom = start
                }
                if (end.isValid()) {
                    this.holidayTo = end
                }
            },
        },
    },

    methods: {
        handleManualDateInput ($event) {
            this.parsedHolidayRange = $event.target.value
            $event.target.blur()
            this.$refs.dateRangePicker.open = false
        },
        formatDate (date, allDay) {
            return date.format(allDay ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm')
        },
        holidayRangeUpdate (value) {
            this.holidayFrom = moment(value.startDate)
            this.holidayTo = moment(value.endDate)
        },
        submitHoliday () {
            const data = {
                holidayName: this.holidayName,
                holidayFrom: this.holidayFrom.format(this.allDay ? 'YYYY-MM-DD 00:00:00' : 'YYYY-MM-DD HH:mm:00'),
                holidayTo: this.holidayTo.format(this.allDay ? 'YYYY-MM-DD 23:59:59' : 'YYYY-MM-DD HH:mm:00'),
                yearly: this.yearly,
            }
            this.addJob(this.formAction)
            this.loading = true

            this.$api.post(this.formAction, data).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Holiday was saved`,
                })

                setTimeout(() => {
                    this.$router.push({
                        name: 'SupplierHolidays',
                        params: {
                            supplierId: this.supplierId,
                        },
                    })
                }, 100)
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving holiday failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(this.formAction)
            })
        },
        fetchHolidays () {
            if (this.isEdit === false) {
                this.holidayFrom = moment()
                this.holidayTo = moment().add(6, 'days')

                return
            }

            this.loading = true
            this.$store.dispatch('holidays/fetchSupplierHolidays', this.supplierId)

            this.$watch(() => {
                return this.$store.getters['holidays/getHolidayBySupplierIdAndHolidayId'](this.supplierId, this.$route.params.holidayId)
            }, (holiday) => {
                this.loading = false

                this.holidayName = holiday.holidayName
                this.holidayFrom = moment(holiday.holidayFrom)
                this.holidayTo = moment(holiday.holidayTo)
                this.yearly = holiday.yearly

                if (
                    this.holidayFrom.hours() === 0 &&
                    this.holidayFrom.minutes() === 0 &&
                    this.holidayTo.hours() === 23 &&
                    this.holidayTo.minutes() === 59
                ) {
                    this.allDay = true
                }
            })
        },
    },
    created () {
        this.fetchHolidays()
    },
}
</script>

<style lang="scss" scoped>
    .form {
        max-width: 900px;
    }
</style>
